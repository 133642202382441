
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component({})
  export default class Tool extends Vue {
    @Prop({ default: false })
    protected readonly checked!: boolean;

    protected get toolClasses(): object {
      return {
        "lz-tool--checked": this.checked
      };
    }
  }
