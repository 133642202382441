
  import { Component, Vue } from "vue-property-decorator";
  import LzTool from "./components/Tool.vue";
  import LzButton from "@/components/Button.vue";
  import { apiMembers } from "@/modules/auth/api";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  @Component({
    components: { LzTool, LzButton }
  })
  export default class StepTools extends Vue {
    tools: Record<Member["tools"][number], boolean> = {
      projects: false,
      shop: false,
      events: false,
      services: false,
      reviews: false,
      bookings: false
    };

    loadingPostStepTools = false;

    @auth.State("id")
    public memberId!: string;

    @auth.Mutation
    public setTools!: (payload: any) => void;

    get disableStepToolsButton(): boolean {
      return (
        !Object.values(this.tools).some(value => value === true) ||
        this.loadingPostStepTools
      );
    }

    async sendStepTools() {
      this.loadingPostStepTools = true;

      const selectedTools = Object.keys(this.tools).filter(
        key => this.tools[key]
      ) as Member["tools"];

      this.setTools(selectedTools);

      await apiMembers.update(this.memberId, { tools: selectedTools });

      this.loadingPostStepTools = false;
    }
  }
