
  import LzBox from "@/components/Box.vue";
  import LzButton from "@/components/Button.vue";
  import LzLinkIcon from "@/components/LinkIcon.vue";
  import LzModal from "@/components/Modal.vue";
  import { Component, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { apiOrganizations } from "../modules/organization/api";
  import { apiMembers } from "@/modules/auth/api";

  const auth = namespace("auth");
  type OrganizationPlan = {
    id: string;
    SubscriptionPlan: SubscriptionPlan;
  };

  @Component({
    components: { LzButton, LzModal, LzLinkIcon, LzBox }
  })
  export default class Subscription extends Vue {
    loaded = false;

    subscriptionPlans: SubscriptionPlan[] = [];
    organizationPlan = {} as OrganizationPlan;

    @auth.State("id")
    public memberId?: string;

    member: Member;

    @auth.State("currencySymbol")
    currencySymbol?: string;

    redirectToPlan(url: string) {
      if (url) {
        window.location.href = url;
      }
    }

    isSelectedPlan(plan: string) {
      return plan === this.organizationPlan?.SubscriptionPlan?.id;
    }

    async getSubscriptionPlans() {
      await apiOrganizations
        .getSubscriptionPlans()
        .then(({ data: plans }) => {
          this.subscriptionPlans = plans;
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.subscription.plans")
          });
        });
    }

    async getOrganizationPlan() {
      if (!this.memberId || this.$route.path === "/auth/onboarding") return;

      await apiOrganizations
        .getOrganizationPlan(this.memberId)
        .then(({ data }) => {
          if (!data) return;

          const { id, SubscriptionPlan } = data;
          this.organizationPlan.id = id;
          this.organizationPlan.SubscriptionPlan = SubscriptionPlan;
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });
    }

    async mounted() {
      try {
        await this.getSubscriptionPlans();
        await this.getOrganizationPlan();
        await apiMembers
          .getById(this.memberId)
          .then(data => (this.member = { ...this.member, ...data }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.loaded = true;
      }
    }

    goToPayment(url: string) {
      const params = new URLSearchParams({
        first_name: this.member.firstName,
        last_name: this.member.lastName,
        email: this.member.email,
        cf_memberid: this.memberId,
        company_name: this.member.companyName
      });

      window.location.href = `${url}?${params}`;
    }
  }
