
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { namespace } from "vuex-class";
  import { getImgURL } from "@/utils/getFormulateImageUrl";
  import { apiWebsite } from "@/modules/web/api";

  const auth = namespace("auth");

  @Component({
    components: { LzButton }
  })
  export default class StepStyle extends Vue {
    @auth.State("style")
    public style!: any;

    @auth.Action
    public setTemplateFeatures!: () => Promise<void>;

    styleForm = {
      logo: this.style?.logo ?? "",
      textColor: this.style?.textColor ?? "#000000",
      buttonColor: this.style?.buttonColor ?? "#000000",
      template: this.style?.template ?? "modern"
    };

    loadingPostStyle = false;

    //TODO: show the styles but disable them
    styleOptions = {
      // classic: this.$t("auth.onboarding.stepStyle.form.styleOptions.classic")
      modern: this.$t("auth.onboarding.stepStyle.form.styleOptions.modern")
      // minimalist: this.$t(
      //   "auth.onboarding.stepStyle.form.styleOptions.minimalist"
      // )
    };

    get disableStepStyleButton(): boolean {
      return !this.styleForm.template || this.loadingPostStyle;
    }

    @auth.State("id")
    memberId!: string;

    @auth.State("websiteId")
    websiteId!: string;

    @auth.Mutation
    public setStyle!: (payload: any) => void;

    async onStyleSubmit() {
      this.loadingPostStyle = true;
      const uploadedImage = (await getImgURL(this.styleForm.logo)) as string;

      const style = {
        logo: uploadedImage,
        textColor: this.styleForm.textColor,
        buttonColor: this.styleForm.buttonColor,
        template: this.styleForm.template
      };

      this.setStyle(style);
      await apiWebsite.website.setStyle(this.websiteId, style);

      await this.setTemplateFeatures();

      this.loadingPostStyle = false;
    }
  }
